/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    strong: "strong",
    h3: "h3",
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    tbody: "tbody",
    td: "td",
    pre: "pre",
    code: "code"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("br"), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Event type:"), " processingAccount.status.changed ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Version:"), " 1.0 ", React.createElement("br"), "\n", React.createElement(_components.strong, null, "Resource:"), " Boarding"), "\n", React.createElement(_components.p, null, "We trigger this event when we change the status of a processing account, for example, when we approve the account."), "\n", React.createElement(_components.h3, null, "Notification payload"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Attribute"), React.createElement(_components.th, {
    align: "left"
  }, "Description"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "processingAccountId")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier that we assigned to the processing account.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "status")), React.createElement(_components.td, {
    align: "left"
  }, "Status of the processing account. The status is one of the following values: ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "entered"), " – We have received information about the account, but we have not yet reviewed it. ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "pending"), " – We have reviewed the information about the account, but we have not yet approved it. ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "approved"), " – We have approved the account for processing transactions and funding. ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "subjectTo"), " – We have approved the account, but we require supporting documents. ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "dormant"), " – Account is temporarily closed. ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "nonProcessing"), " – We have approved the account, but the merchant has not yet run a transaction. ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "rejected"), " – We rejected the application for the processing account. ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "terminated"), " – Account is closed. ", React.createElement("br"), React.createElement("br"), "• ", React.createElement(_components.strong, null, "cancelled"), " – Merchant withdrew the application for the processing account.")))), "\n", React.createElement(_components.h3, null, "Example"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-json"
  }, "{\n  \"specversion\": \"1.0\",\n  \"type\": \"processingAccount.status.changed\",\n  \"version\": \"1.0\"\n  \"source\": \"payroc\",\n  \"id\": \"123e4567-e89b-12d3-a456-426614174000\",\n  \"time\": \"2024-05-12T12:34:56.789Z\",\n  \"datacontenttype\": \"application/json\",\n  \"data\": {\n    \"processingAccountId\": \"12345678\",\n    \"status\": \"entered\"\n  }\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
